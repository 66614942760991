.internal-promo {
  border-top: 3px solid red;
  margin: 35px 10px;
  font-weight: bold;
}

.internal-promo h2 {
  text-transform: uppercase;
  color: red;
  margin: 5px 0 10px;
  font-family: tablet_gothic_condensed, sans-serif;
  font-size: 18px;
}

.internal-promo__items:after {
  content: "";
  clear: both;
  width: 100%;
  height: 1px;
  display: block;
}

.internal-promo-items__item {
  float: left;
  width: calc(25% - 15px);
  margin-bottom: 30px;
  margin-right: 20px;
}

.internal-promo-items__item:nth-child(4n+1) {
  clear: both;
}

.internal-promo-items__item:nth-child(4n+4) {
  margin-right: 0;
}

.internal-promo-item__image {
  background: #fff;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 82px;
  margin-bottom: 10px;
  display: flex;
}

.internal-promo-item__image .loading-placeholder {
  justify-content: center;
  align-items: center;
  display: flex;
}

.internal-promo-item__image .loading-placeholder.item-loaded {
  padding-bottom: 0 !important;
}

.internal-promo-item__image .loading-placeholder.item-loaded img {
  object-fit: contain;
  max-width: 140px;
  max-height: 82px;
}

.internal-promo-item__title, .internal-promo-item__more {
  font-family: arial, Helvetica, sans-serif;
  font-size: 15px;
  display: block;
}

.internal-promo-item__title {
  color: #16212d;
  height: 45px;
  margin-bottom: 5px;
  font-size: 13px;
  line-height: 15px;
  text-decoration: none;
  display: block;
  overflow: hidden;
}

.internal-promo-item__title:hover {
  text-decoration: none;
}

.internal-promo-item__more {
  color: red;
  text-transform: lowercase;
  border-bottom: 2px solid red;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.internal-promo-item__more:hover {
  color: #5c6773;
  border-bottom: 0;
  text-decoration: none;
  transition: all .3s linear;
}

.internal-promo-item__more:before, .internal-promo-item__more:after {
  content: "";
  transform-origin: 100% 100%;
  background-color: red;
  width: 2px;
  height: 5px;
  display: block;
  position: absolute;
  top: 5px;
  right: -10px;
  transform: skewX(40deg);
}

.internal-promo-item__more:after {
  transform-origin: 100% 0;
  top: 10px;
  transform: skewX(-40deg);
}

.internal-promo-item__more:hover:before, .internal-promo-item__more:hover:after {
  background-color: #5c6773;
  transition: all .3s linear;
}

@media only screen and (max-width: 699px) {
  .internal-promo-items__item {
    width: calc(50% - 6px);
    margin-right: 6px;
  }

  .internal-promo-items__item:nth-child(2n+2) {
    margin-right: 0;
  }

  .internal-promo-item__image {
    background: #b6c1cc;
    width: 100%;
  }
}

.sc-gLLuof.fRdbir h2 {
  color: #191919;
  border-bottom: 1px solid #e7e7e7;
  font-family: Vafle Classic, Arial, Helvetica, sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.sc-gLLuof.fRdbir label {
  gap: 0;
  flex-direction: column !important;
}

.sc-gLLuof.fRdbir label .eycUNu {
  color: #888;
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.sc-gLLuof.fRdbir label .keVjxN {
  padding: 4px 12px 0 0;
}

.sc-gLLuof.fRdbir label .keVjxN input {
  background: #fff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.sc-gLLuof.fRdbir label .keVjxN textarea {
  background: #fff;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  flex-direction: column;
  height: 100px;
  min-height: 100px;
  padding: 12px;
  display: flex;
}

.sc-gLLuof.fRdbir label.leimcV .keVjxN {
  clear: both;
  padding: 0 12px 0 0;
  position: relative;
  top: -25px;
}

.sc-gLLuof.fRdbir label.leimcV .keVjxN .ciEuJM {
  padding: 5px 0 0 29px;
}

.sc-gLLuof.fRdbir label.leimcV .eycUNu {
  color: #191919;
  z-index: 10;
  padding: 0 0 0 30px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
}

.sc-gLLuof.fRdbir label.leimcV .eycUNu a {
  text-decoration: underline;
}

.sc-beySPh button.sc-dmyCSP {
  color: #000;
  text-align: center;
  cursor: pointer;
  background: #fff;
  border: none;
  width: 24px;
  min-width: 24px;
  height: 24px;
  padding: 0;
  font-size: 20px;
  display: block;
  position: absolute;
  top: 24px;
  right: 24px;
}

@media screen and (max-width: 520px) {
  .sc-gLLuof.fRdbir label.leimcV {
    height: 100px;
    position: relative;
  }

  .sc-gLLuof.fRdbir label.leimcV .keVjxN {
    height: 100px;
    position: absolute;
    top: 4px;
  }

  .sc-gLLuof.fRdbir label.leimcV .keVjxN .ciEuJM {
    margin-top: 35px;
  }
}

@media screen and (max-width: 320px) {
  .sc-gLLuof.fRdbir label.leimcV, .sc-gLLuof.fRdbir label.leimcV .keVjxN {
    height: 130px;
  }

  .sc-gLLuof.fRdbir label.leimcV .keVjxN .ciEuJM {
    margin-top: 60px;
  }
}

